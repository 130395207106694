import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiHttpService } from "../core/api-http.service";
import { DenyListService } from "../services/DenyListService";
import { MatPaginator } from '@angular/material/paginator';
import { Configuration } from '../models/Configuration';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { MomentDateTimeAdapter } from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-deny-list',
  templateUrl: './deny-list.component.html',
  styleUrls: ['./deny-list.component.scss'],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DenyListComponent implements OnInit {
  dataSource: DenyListService;
  search ="";
  total:number;

  displayedColumns = ["securityToken", "securityTokenStatus","surrogatePAN", "truncatedPan"];
  displayedColumnFilters: string[] = [
    'filter'
  ];
  start = null;
  end = null;
  alert ="";
  cardUpdated = false;
  interval : any;
  allConfigurations: Map<string, Configuration>;
  configurations: Configuration[] = [];
  expandedElement: any | null = null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('input', {static: true}) input: ElementRef;


  constructor(private httpService: ApiHttpService) {
  }

  ngOnInit(): void {
    this.dataSource = new DenyListService(this.httpService);
    this.dataSource.loadMediaDenyList(0, 10);
    this.total = this.dataSource.total();
    let filter = this.search;
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadMediaDenyList( this.paginator.pageIndex, this.paginator.pageSize, filter, filter))
      )
      .subscribe();

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchByFilter();
        })
      )
      .subscribe();
  }

  searchByFilter(){
    this.paginator.pageIndex = 0;
    let filter = this.search;
    this.dataSource.loadMediaDenyList(this.paginator.pageIndex, this.paginator.pageSize, filter,filter)
  }
}
