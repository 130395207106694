import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiHttpService } from '../core/api-http.service';
import { Crypto } from '../services/Crypto';


@Component({
  selector: 'app-web-tokenizer',
  templateUrl: './webTokenizer.component.html',
  styleUrls: ['./webTokenizer.component.scss']
})
export class WebTokenizerComponent implements OnInit {

  cryptoService: Crypto = new Crypto();
  alert = '';
  alertMessage = '';

  list = [
    'standard', 'securityToken', 'sha256', 'hmacSha256', 'none'
  ];

  form = {
    hash: 'securityToken',
    name: '',
    collapse: false,
    cardNumber: '',
    additionalSecurityTokens: [],
    merchantId: 'fakeMerchantId',
    surrogatePAN: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    currentYear: (new Date().getFullYear()).toString(),
    year1: (new Date().getFullYear().valueOf() + 1).toString(),
    year2: (new Date().getFullYear().valueOf() + 2).toString(),
    year3: (new Date().getFullYear().valueOf() + 3).toString(),
    year4: (new Date().getFullYear().valueOf() + 4).toString()
  };

  params = {
    cancelUrl: '',
    successUrl: '',
    tokenizationSessionId: ''
  };

  constructor(private activatedRoute: ActivatedRoute, private httpService: ApiHttpService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = {
        cancelUrl: atob(params.cancelUrl),
        successUrl: atob(params.successUrl),
        tokenizationSessionId: atob(params.tokenizationSessionId)
      };
    });
  }

  // tslint:disable-next-line:typedef
  changeSwitch() {
    this.form.additionalSecurityTokens = [];
  }

  // tslint:disable-next-line:typedef
  updateSurrogatePan() {
    if (this.form.hash === 'securityToken') {
      this.form.surrogatePAN = this.cryptoService.hmacSha256(this.form.cardNumber);
    } else if (this.form.hash === 'sha256') {
      this.form.surrogatePAN = this.cryptoService.sha256(this.form.cardNumber);
    } else if (this.form.hash === 'hmacSha256') {
      this.form.surrogatePAN = this.cryptoService.hmacSha256(this.form.cardNumber);
    } else if (this.form.hash === 'standard') {
      this.form.surrogatePAN = this.cryptoService.standard(this.form.cardNumber);
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }
}
