<div class="body">
  <br>
  <div>
    <div>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-description> Deny list </mat-panel-description>
        </mat-expansion-panel-header>

        <div align="end">
          <div style="display: flex;">
            <div>
              <mat-form-field class="search-input">
                <mat-label>Search</mat-label>
                <input #input matInput type="text" [(ngModel)]="search">
                <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                        (click)="search='';searchByFilter()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <button style="height:100%; width: 100%;" mat-raised-button (click)="searchByFilter()" color="primary">refresh</button>
        </div>

        <div>
          <div class="spinner-container" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
          </div>

          <mat-table multiTemplateDataRows [dataSource]="dataSource">
            <ng-container matColumnDef="securityToken">
              <mat-header-cell class="cell-securityToken" *matHeaderCellDef> Security Token </mat-header-cell>
              <mat-cell class="cell-securityToken" *matCellDef="let media">{{media.securityToken}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="securityTokenStatus">
              <mat-header-cell class="cell-securityTokenStatus" *matHeaderCellDef> Security Token Status </mat-header-cell>
              <mat-cell class="cell-securityTokenStatus" *matCellDef="let media">{{media.securityTokenStatus}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="surrogatePAN">
              <mat-header-cell class="surrogatePAN" *matHeaderCellDef> Surrogate Pan </mat-header-cell>
              <mat-cell class="surrogatePAN" *matCellDef="let media">{{media.surrogatePAN}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="truncatedPan">
              <mat-header-cell class="cell" *matHeaderCellDef> Truncated Pan </mat-header-cell>
              <mat-cell class="cell" *matCellDef="let media">{{media.truncatedPan}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="example-element-row" *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator #paginator [length]="this.dataSource.total()" [pageSize]="10" [pageSizeOptions]="[3, 5, 10]">
          </mat-paginator>
        </div>

      </mat-expansion-panel>
    </div>
  </div>
</div>
