<div class="body">
<br>
  <div>
    <div>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-description> Deny List Version </mat-panel-description>
      </mat-expansion-panel-header>

    <div align="end">
      <div style="display: flex;">
        <div>
          <mat-form-field class="search-input">
            <mat-label>Search</mat-label>
            <input #input matInput type="text" [(ngModel)]="myfilter">
            <button mat-button *ngIf="myfilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="myfilter='';filterList()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        </div>
        <button style="height:100%; width: 100%;" mat-raised-button (click)="filterList()" color="primary">refresh</button>
      </div>

    <div>
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner></mat-spinner>
      </div>

      <mat-table multiTemplateDataRows [dataSource]="dataSource">
        <ng-container matColumnDef="denyListVersion">
          <mat-header-cell class="cell-denyListVersion" *matHeaderCellDef> List Version </mat-header-cell>
          <mat-cell class="cell-denyListVersion" *matCellDef="let denyVersion">{{denyVersion.version}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <mat-header-cell class="cell-creationDate" *matHeaderCellDef> Creation Date </mat-header-cell>
          <mat-cell class="cell-creationDate" *matCellDef="let denyVersion">{{denyVersion.creationDate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="denyListVersionUrl">
          <mat-header-cell class="cell-denyListVersionUrl" *matHeaderCellDef> URL </mat-header-cell>

          <mat-cell *matCellDef="let denyVersion">
            <button mat-icon-button (click)="onloadFile(denyVersion)">
              <mat-icon>visibility</mat-icon>
            </button>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row class="example-element-row" *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>
      <mat-paginator #paginator [length]="this.dataSource.total()" [pageSize]="10" [pageSizeOptions]="[3, 5, 10]">
      </mat-paginator>
    </div>
    </mat-expansion-panel>
    </div>
  </div>
</div>
