import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DenyListVersionService } from "../services/DenyListVersionService";
import { ApiHttpService } from "../core/api-http.service";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { DenyListExchange } from "../models/deny-list-exchange";
import * as fileSaver from "file-saver";


@Component({
  selector: 'app-denylist-version',
  templateUrl: './denylist-version.component.html',
  styleUrls: ['./denylist-version.component.scss']
})
export class DenylistVersionComponent implements OnInit {

  dataSource: DenyListVersionService;

  total: number;
  myfilter: string;
  data: DenyListExchange;

  displayedColumns = ["denyListVersion", "creationDate","denyListVersionUrl"];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('input', {static: true}) input: ElementRef;

  constructor(private httpSercice: ApiHttpService) { }


  ngOnInit(): void {
    this.dataSource = new DenyListVersionService(this.httpSercice)
    this.dataSource.loadMediaDenyListVersion(0, 10);
    this.total = this.dataSource.total();
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadMediaDenyListVersion( this.paginator.pageIndex, this.paginator.pageSize))
      )
      .subscribe();
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filterList();
        })
      ).subscribe();
  }

  filterList() {
    this.paginator.pageIndex = 0;
    let filter = this.myfilter;
    this.dataSource.loadMediaDenyListVersion(this.paginator.pageIndex, this.paginator.pageSize, filter, filter);
  }

  onloadFile(denyVersion: any): void {

    const str = denyVersion.links[0].href.split(new RegExp('\\{?\\?'));
    const url = str[0] + '?keySaltVersion=foo&merchantId=bar';

    fileSaver.saveAs(url, "denyListVersion.json");
  }

}
