import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { ApiHttpService } from "../core/api-http.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

export class DenyListVersionService implements DataSource<any> {

  private denyListVersionSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalElements: number;
  public loading$ = this.loadingSubject.asObservable();

  constructor(private httpService: ApiHttpService) {
  }

  loadMediaDenyListVersion(pageIndex = 0, pageSize = 10, version?:string, creationDate?:string){
    this.loadingSubject.next(true);

    this.httpService.getDenyListVersion(pageIndex, pageSize, version, creationDate)
      .pipe(catchError(() => of([])), finalize(() => this.loadingSubject.next(false)))
      .subscribe(denyVersion => {
        this.denyListVersionSubject.next(denyVersion.content);
        this.totalElements = denyVersion.page.totalElements
      });
  }

  total() {
    return this.totalElements;
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.denyListVersionSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.denyListVersionSubject.complete();
    this.loadingSubject.complete();
  }
}
