export class PaymentAuthorization {
  seqId: number;
  responseStatus: string;
  responseReason: string;
  amount: number;
  authorizationCode: string;
  approvalCode: string;
  responseCode: string;
  capturePossible: string;
}
