import { Component} from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "./core/auth.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'ifsng-webapp-pgs';
  showNav = true;
  constructor(private router: Router, private authService:AuthService, private route:Router) {
    this.router.events.subscribe(event => {
      if (event['routerEvent']){
        if (event['routerEvent'].url.startsWith("/webTokenizer")){
          this.showNav = false;
        }else  if (event['routerEvent'].url.startsWith("/login")){
          console.log("login ===> ", authService.isLogged());

          if (authService.isLogged()){
            router.navigate(['/home']);
          }
          this.showNav = false;
        } else {
          console.log("all ===> ", authService.isLogged());
          if (!authService.isLogged()){
            router.navigate(['/login']);

          }
          this.showNav = true;
        }
      }
    })
  }

  logout(){
    console.log("logout");
    this.authService.logout();
  }

}
