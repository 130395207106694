<!DOCTYPE html>
<html lang="en" ng-app="psp">
<head>
  <meta charset="utf-8">
  <meta content="IE=edge" http-equiv="X-UA-Compatible">
  <meta content="width=device-width, initial-scale=1" name="viewport">
  <!-- The above 3 meta tags *must* come first in the head; any other head content must come *after* these tags -->
  <meta content="" name="description">
  <meta content="" name="author">
  <meta content="text/html;charset=utf-8" http-equiv="Content-Type">
  <meta content="utf-8" http-equiv="encoding">
  <title>Payment service provider</title>
</head>
<body>
<div class="container well" hidden="true">
  <h1>Bank Card Tokenizer</h1>
</div>
<div class="container">
  <div *ngIf="alert === 'OK'" class="alert alert-success alert-pgs" role="alert">
    {{alertMessage}}
  </div>
  <div *ngIf="alert === 'KO'" class="alert alert-danger alert-pgs" role="alert">
    {{alertMessage}}
  </div>
  <form
    [action]="params.successUrl"
    class="form-horizontal"
    method="post"
    ngNoForm>
    <div class="form-group">
      <input [(ngModel)]="form.merchantId" hidden="true" matInput name="merchantId">
      <input [(ngModel)]="form.surrogatePAN" hidden="true" matInput name="surrogatePAN">
      <input [(ngModel)]="params.tokenizationSessionId" hidden="true" matInput name="tokenizationSessionId">
      <div class="container">
        <div class="row mt-4">
          <div class="col offset-lg-2 col-lg-8">
            <div class="card border-dark">
              <div class="card-header">
                PGS Payment gateway web tokenizer
              </div>

              <div class="card-body ">
                <h4 class="card-title">PAYMENT INFORMATION</h4>

                <p class="card-text">Transaction reference:
                  <strong>f756b466-6155-4909-aa96-e8b8145ad22f@021474f3-01d9-46bb-9557-109047b0370b</strong>
                </p>
                <p class="card-text">Order value: <strong>0 EUR</strong></p>
                <p class="card-text">Merchant identifier: <strong>***TEST*** BEATS</strong></p>
                <h4 class="card-title">PAYMENT DATA</h4>
                <p class="card-text">Please enter your payment data</p>

                <div class="container">
                  <div class="form-group">
                    <label for="card-number">Card number*</label>
                    <input
                      [(ngModel)]="form.cardNumber"
                      [maxLength]="form.hash === 'none' ? 200 : 16 "
                      aria-describedby="helpId"
                      class="form-control"
                      #creditCardNumber
                      pattern="[0-9]*"
                      id="card-number" name="creditCardNumber" placeholder="Debit/Credit Card Number"
                      required="true" type="text"
                      (ngModelChange)="updateSurrogatePan()">
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="ExpiryMonth">Expiry Month</label>
                        <select class="form-control" id="ExpiryMonth" name="">
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="ExpiryYear">Expiry Year</label>
                        <select class="form-control" id="ExpiryYear" name="">
                          <option>{{form.currentYear}}</option>
                          <option>{{form.year1}}</option>
                          <option>{{form.year2}}</option>
                          <option>{{form.year3}}</option>
                          <option>{{form.year4}} </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="cvv">CVV</label>
                    <input class="form-control" id="cvv" name="" placeholder=""
                           type="password">
                  </div>
                  <div class="custom-control custom-switch">
                    <input aria-controls="collapseExample" aria-expanded="false"
                           class="custom-control-input"
                           data-target="#collapseExample" data-toggle="collapse" id="customSwitch1"
                           (change)="this.changeSwitch()"
                           type="checkbox">
                    <label class="custom-control-label" for="customSwitch1">Emulate several security
                      token</label>
                  </div>
                  <div class="collapse" id="collapseExample">
                    <div class="card card-body">
                      <div class="form-group">
                        <label>Security token</label>
                        <input
                          [(ngModel)]="form.additionalSecurityTokens[0]"
                          aria-describedby="helpId"
                          class="form-control"
                          id="additionalSecurityTokens0"
                          name="additionalSecurityTokens"
                          type="text">
                        <input
                          [(ngModel)]="form.additionalSecurityTokens[1]"
                          aria-describedby="helpId"
                          class="form-control"
                          id="additionalSecurityTokens1"
                          name="additionalSecurityTokens"
                          type="text">
                        <input
                          [(ngModel)]="form.additionalSecurityTokens[2]"
                          aria-describedby="helpId"
                          class="form-control"
                          id="additionalSecurityTokens2"
                          name="additionalSecurityTokens"
                          type="text">
                        <input
                          [(ngModel)]="form.additionalSecurityTokens[3]"
                          aria-describedby="helpId"
                          class="form-control"
                          id="additionalSecurityTokens3"
                          name="additionalSecurityTokens"
                          type="text">
                        <input
                          [(ngModel)]="form.additionalSecurityTokens[4]"
                          aria-describedby="helpId"
                          class="form-control"
                          id="additionalSecurityTokens4"
                          name="additionalSecurityTokens"
                          type="text">
                      </div>
                    </div>
                  </div>
                  <div class="button-groupe">
                    <div class="row">
                      <form
                        [action]="params.cancelUrl"
                        method="post"
                        ngNoForm>
                        <input [(ngModel)]="params.tokenizationSessionId" hidden="true" matInput name="tokenizationSessionId">
                        <button color="warn" mat-raised-button name="cancel"
                                style="margin-right: 10px" type="submit"
                                value="Cancel">
                          Cancel
                        </button>
                      </form>
                      <button [disabled]="!form.cardNumber" color="primary" mat-raised-button
                              type="submit">Return to
                        Transcity UP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted web-tokenizer-footer">
                <div class="panel-heading">
                  Request params :
                </div>
                <div class="panel-body">
                  <div class="form-group"  >
                    <label
                      class="col-lg-12 control-label">Tokenization SessionId :</label> <span  class="col-lg-12 control-label"> {{ params.tokenizationSessionId }}</span>
                  </div>
                  <div class="form-group"  >
                    <label class="col-lg-12 control-label">Success Url :</label>
                    <span  class="col-lg-12 control-label"
                           style="overflow-wrap: anywhere">{{ params.successUrl }}
                    </span>
                  </div>
                  <div class="form-group"  >
                    <label class="col-lg-12 control-label">Cancel Url :</label>
                    <span
                      class="col-lg-12 control-label"
                      style="overflow-wrap: anywhere"
                    >{{ params.cancelUrl }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
</body>
</html>
