import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import  {MatSlideToggleModule } from '@angular/material/slide-toggle';
import  {MatTableModule } from '@angular/material/table';
import  {MatPaginatorModule } from '@angular/material/paginator';
import  {MatSortModule } from '@angular/material/sort';
import  {MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import  {MatInputModule } from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {MatSelectModule} from '@angular/material/select';
import { WebTokenizerComponent } from "./webTokenizer/webTokenizer.component";
import { ProvisionEMVMediaComponent } from "./provisionEMVMedia/provisionEMVMedia.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AcceptlistComponent } from "./acceptList/acceptlist.component";
import { DenyListComponent } from './deny-list/deny-list.component';
import { DenylistVersionComponent } from './denylist-version/denylist-version.component';
import { TokenInterceptor } from "./core/http.interceptor";
import { LoginComponent } from "./login/login.component";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AcceptlistComponent,
    WebTokenizerComponent,
    DenyListComponent,
    LoginComponent,
    DenylistVersionComponent,
    ProvisionEMVMediaComponent
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(maskConfig),
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    HttpClientModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
