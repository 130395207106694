import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebTokenizerComponent } from "./webTokenizer/webTokenizer.component";
import { ProvisionEMVMediaComponent } from "./provisionEMVMedia/provisionEMVMedia.component";
import { HomeComponent } from "./home/home.component";
import { AcceptlistComponent } from "./acceptList/acceptlist.component";
import { DenyListComponent } from "./deny-list/deny-list.component";
import { DenylistVersionComponent } from "./denylist-version/denylist-version.component";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [

  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'acceptList', component: AcceptlistComponent},
  {path: 'denyList', component: DenyListComponent},
  {path: 'denyListVersion', component: DenylistVersionComponent},
  {path: 'webTokenizer', component: WebTokenizerComponent},
  {path: 'provisionEMVMedia', component: ProvisionEMVMediaComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {  useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
