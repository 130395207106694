<div *ngIf="alert === 'OK'" class="alert alert-success alert-pgs" role="alert">
   {{alertMessage}}
</div>
<div *ngIf="alert === 'KO'" class="alert alert-danger alert-pgs" role="alert">
  {{alertMessage}}
</div>

<div class="body">
  <br>
  <div>
    <div>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-description> Accept List </mat-panel-description>
        </mat-expansion-panel-header>

        <div align="end">
          <div style="display: flex;">
            <div>
              <mat-form-field class="search-input">
                <mat-label>Search</mat-label>
                <input #input matInput type="text" [(ngModel)]="search">
                <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                        (click)="search='';searchByFilter()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <button style="height:100%; width: 100%;" mat-raised-button (click)="searchByFilter()" color="primary">refresh</button>
        </div>

        <div>
          <div class="spinner-container" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
          </div>

          <mat-table multiTemplateDataRows [dataSource]="dataSource">

            <ng-container class="mat-elevation-z8" matColumnDef="surrogatePan">
              <mat-header-cell class="cell-surrogatePan" *matHeaderCellDef> SurrogatePan </mat-header-cell>
              <mat-cell class="cell-surrogatePan"
                        *matCellDef="let event">{{event.surrogatePAN}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="merchantId">
              <mat-header-cell class="cell-origin" *matHeaderCellDef> Merchant ID</mat-header-cell>
              <mat-cell class="cell-origin" *matCellDef="let event">{{event.merchantId}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="truncatedPan">
              <mat-header-cell class="cell" *matHeaderCellDef>Card</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">{{event.truncatedPan}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row class="example-element-row" *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator #paginator [length]="this.dataSource.total()" [pageSize]="10" [pageSizeOptions]="[3, 5, 10]">
          </mat-paginator>
        </div>

      </mat-expansion-panel>
    </div>
  </div>
</div>
