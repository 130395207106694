<div *ngIf="alert === 'OK'" class="alert alert-success alert-pgs" role="alert">
   {{alertMessage}}
</div>
<div *ngIf="alert === 'KO'" class="alert alert-danger alert-pgs" role="alert">
  {{alertMessage}}
</div>

<div class="body">
  <div >
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-description> </mat-panel-description>
        <mat-panel-description style="color: black;">
          Configuration
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="configuration" style="display: flex;">
        <div  *ngFor="let configuration of configurations">
          <div class="configuration-group" *ngIf="configuration.requestType != 'SETTLEMENT'" >
          <label id="example-radio-group-label">{{configuration.requestType}}</label>
          <label id="example-radio-group-label">Mode : </label>
          <mat-radio-group *ngIf="configuration.requestType != 'PAYMENT_DATA' " aria-labelledby="radio-group-label"
                           class="radio-group">
            <mat-radio-button (change)="radioChange($event, configuration)"
                              [checked]="mode.value === configuration.mode" class="radio-button"
                              *ngFor="let mode of modes"
                              [value]="mode">
              <span> {{ mode.label }} </span>
            </mat-radio-button>
          </mat-radio-group>
          <div *ngIf="configuration.requestType === 'PAYMENT_DATA'">
            <div>
              <mat-slide-toggle [(ngModel)]="configuration.failure"> Server Failure</mat-slide-toggle>
            </div>

            <div>
              <mat-form-field>
                <mat-label>Reason Code</mat-label>
                <input [readonly]="!configuration.failure" matInput type="text" [(ngModel)]="configuration.reasonCode">
                <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>


          <mat-form-field *ngIf="configuration.requestType != 'PAYMENT_DATA' ">
            <mat-label>Delay Time in Minutes</mat-label>
            <input matInput [readonly]="configuration.mode != 'DELAYED'" type="text"
                   [(ngModel)]="configuration.duration">
          </mat-form-field>
          <button (click)="updateConfiguration($event, configuration)" mat-raised-button color="primary">Update</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <br>

  <div>
    <div>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div align="end">
          <div style="display: flex;">
            <div>
              <mat-form-field class="search-input">
                <mat-label>Search</mat-label>
                <input #input matInput type="text" [(ngModel)]="search">
                <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                        (click)="search='';searchByFilter()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  style="margin-left: 20px" class="search-input">
                <mat-label>From - To</mat-label>
                <input  (dateTimeChange)="changeRange()" readonly [owlDateTimeTrigger]="dt1" matInput  [owlDateTime]="dt1"
                        [selectMode]="'range'" [(ngModel)]="selectedMoment" >
                <button mat-button *ngIf="selectedMoment" matSuffix mat-icon-button aria-label="Clear"
                        (click)="selectedMoment=null;start = null; end = null;searchByFilter()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <owl-date-time #dt1></owl-date-time>


            </div>
          </div>
          <button style="height:100%; width: 100%;" mat-raised-button (click)="searchByFilter()" color="primary">refresh</button>


        </div>

        <div >
          <div class="spinner-container" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
          </div>
          <mat-table multiTemplateDataRows class="events-table mat-elevation-z8" [dataSource]="dataSource">

            <ng-container matColumnDef="Date time">
              <mat-header-cell class="cell-date" *matHeaderCellDef># Date time</mat-header-cell>
              <mat-cell class="cell-date"
                        *matCellDef="let event">{{event.dateTime | date:'dd-MM-yyyy HH:mm:ss'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Origine">
              <mat-header-cell class="cell-origin" *matHeaderCellDef>Origine</mat-header-cell>
              <mat-cell class="cell-origin" *matCellDef="let event">{{event.source}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Type">
              <mat-header-cell class="cell" *matHeaderCellDef>Type</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">{{event.requestType}}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="Card">
              <mat-header-cell class="cell" *matHeaderCellDef>Card</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">{{event.truncatedPan}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="correlationId">
              <mat-header-cell class="cell" *matHeaderCellDef>CorrelationId</mat-header-cell>
              <mat-cell class="cell" (click)="search=event.correlationId; searchByFilter()" *matCellDef="let event">{{event.correlationId}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Amount">
              <mat-header-cell class="cell" *matHeaderCellDef>Amount</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">{{event?.content?.amount || 0}} {{getCurrencyCode(event?.content?.currencyCode)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="State">
              <mat-header-cell class="cell" *matHeaderCellDef>State</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">
                <button class="pgs-button pgs-button-orange" *ngIf="event.status==='MANUAL' || event.status=== 'MANUAL_PROCESSED'" mat-flat-button >FAILED (MANUAL) </button>
                <button class="pgs-button pgs-button-green" *ngIf="event.status === 'SUCCESS' && event.status!='MANUAL'" mat-flat-button color="primary">SUCCESS</button>
                <button class="pgs-button pgs-button-peachpuff" *ngIf="event.status === 'DELAYED' || event.status === 'DELAYED_PROCESSED' " mat-flat-button >FAILED (DELAYED)</button>
                <button class="pgs-button" *ngIf="event.status === 'FAILURE'" mat-flat-button color="warn">FAILED</button>

              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Action">
              <mat-header-cell class="cell" *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell class="cell" *matCellDef="let event">
                  <button class="pgs-button" *ngIf="event.status === 'MANUAL' && event.correlationStatus === 'MANUAL'" (click)="openDialog(event, processSecurityModal)"
                        mat-raised-button color="primary">
                        Process Next

                  </button>
                  <button class="pgs-button" *ngIf="event.status === 'MANUAL' && (event.requestType === 'AUTHORIZATION' || event.requestType === 'PAYMENT_AUTHORIZATION' || event.requestType === 'REVERSAL')"
                          (click)="openPaymentAuthorizationDialog(event, processAuthorizationModal)"
                  mat-raised-button color="primary">
                      Process
                  </button>
                <!-- debt recovery -->
                <button class="pgs-button" *ngIf="event.status === 'MANUAL' && event.requestType === 'DEBT_RECOVERY'" (click)="openDebtRecoveryDialog(event,debtRecoveryModal)"
                        mat-raised-button color="primary">
                  Process
                </button>
               </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let event; let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                  <div *ngIf="((event.status != 'MANUAL' || event.status != 'DELAYED' ) && event.requestType != 'PAYMENT_AUTHORIZATION') || event.status === 'SUCCESS'" class="example-element-diagram">
                    <div *ngFor="let prop of event.content | keyvalue">
                      <div *ngIf="prop.key!='statementInformation'" class="property">
                        <div class="key">{{prop.key}}:</div>
                        <div class="value"> {{prop.value}}</div>
                      </div>

                      <div class="example-element-diagram" *ngIf="prop.key==='statementInformation'" class="property">
                        <div class="key">{{prop.key}}:</div>
                      </div>

                        <div *ngFor="let p of prop.value | keyvalue">
                          <div class="propertyStatementInformation">
                            <div class="key">{{p.key}}:</div>
                            <div class="value"> {{p.value}}</div>
                          </div>

                        </div>


                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row class="example-element-row" *matRowDef="let row; columns: displayedColumns"
                     [class.example-expanded-row]="expandedElement === element"
                     (click)="expandedElement = expandedElement === row ? null : row;"
                     [class.example-expanded-row]="expandedElement === row"></mat-row>
            <mat-row mat-row *matRowDef="let row; columns: ['expandedDetail']"
                     [class.example-expanded-row2]="!(expandedElement === row)" class="example-detail-row"></mat-row>


          </mat-table>


          <mat-paginator #paginator [length]="this.dataSource.total()" [pageSize]="10" [pageSizeOptions]="[3, 5, 10]">
          </mat-paginator>
        </div>

      </mat-expansion-panel>
    </div>


  </div>
</div>



<ng-template #processSecurityModal  >
  <div style="width: 400px;">
    <h2 mat-dialog-title>Process</h2>
    <mat-dialog-content class="mat-typography">
        <table>
          <tr>
            <td>
              <mat-form-field  class="process-input">
                <mat-label>Correlation</mat-label>
                <input disabled matInput [(ngModel)]="correlationId" [value]="selectedEvent.correlationId">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="process-input">
                <mat-label>Card</mat-label>
                <input [disabled]="maskedPAN != '-' && !cardUpdated" matInput (ngModelChange)="cardUpdated=true" [(ngModel)]="maskedPAN" value="">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="process-input">
                <mat-label>Issuer country code</mat-label>
                <input [disabled]="maskedPAN != '-' && !cardUpdated" matInput (ngModelChange)="cardUpdated=true" [(ngModel)]="issuerCountryCode" value="">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="process-input">
                <mat-label>Transaction Currency Code</mat-label>
                <input type="number" [disabled]="maskedPAN != '-' && !cardUpdated" matInput (ngModelChange)="cardUpdated=true" [(ngModel)]="transactionCurrencyCode" value="">
                <span matSuffix *ngIf="getCurrencyCode(transactionCurrencyCode) !== transactionCurrencyCode">({{getCurrencyCode(transactionCurrencyCode)}})</span>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="process-input">
                <mat-label>Application Primary Account SequenceNumber</mat-label>
                <input [disabled]="maskedPAN != '-' && !cardUpdated" matInput (ngModelChange)="cardUpdated=true" [(ngModel)]="applicationPrimaryAccountSequenceNumber" value="">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field appearance="fill"  class="process-input">
                <mat-label>Expirty date</mat-label>
                <input matInput [disabled]="maskedPAN != '-' && !cardUpdated" (click)="picker.open()" [(ngModel)]="expiryDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

            </td>
          </tr>
          <tr>
              <td colspan="2">
              <mat-form-field style="width:100%"   class="process-input" >
                <mat-label>Card Type Mnemonic</mat-label>
                <mat-select [disabled]="paymentSchemeIdentification !='-' && !cardUpdated" (ngModelChange)="cardUpdated=true" [(ngModel)]="paymentSchemeIdentification">
                  <mat-option value="-">  </mat-option>
                  <mat-option value="VISA"> VISA </mat-option>
                  <mat-option value="MASTERCARD"> MASTERCARD </mat-option>
                  <mat-option value="AMERICANEXPRESS"> AMERICAN EXPRESS </mat-option>

                </mat-select>
              </mat-form-field>

            </td>
          </tr>
        </table>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="process()" cdkFocusInitial>Process</button>
    </mat-dialog-actions>
  </div>
</ng-template>


<ng-template #processAuthorizationModal  >
  <div style="width: 400px;">
    <h2 mat-dialog-title>Process</h2>
    <mat-dialog-content class="mat-typography">
        <table>
          <tr>
            <td colspan="2">
            <mat-form-field style="width:100%"  appearance="fill">
              <mat-label>Response Status</mat-label>
              <mat-select [(ngModel)]="paymentAuthorization.responseStatus">
                <mat-option value="-">  </mat-option>
                <mat-option value="HARD_DECLINE"> Hard Declined </mat-option>
                <mat-option value="SOFT_DECLINE"> Soft Declined </mat-option>
                <mat-option value="APPROVED"> Approved </mat-option>
<!--                <mat-option value="PARTIALLY_APPROVED"> Partially approved  </mat-option>-->
              </mat-select>
            </mat-form-field>

          </td>
        </tr>
          <tr>
            <td>
              <mat-form-field >
                <mat-label>Response Reason </mat-label>
                <input matInput [(ngModel)]="paymentAuthorization.responseReason" [value]="selectedEvent.correlationId">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>Amount</mat-label>
                <!-- <input  matInput ([(ngModel)]="paymentAuthorization.amount" value=""> -->
                <input matInput [disabled]="paymentAuthorization.responseStatus != 'PARTIALLY_APPROVED'" type="number" [(ngModel)]="paymentAuthorization.amount" [value]="paymentAuthorization.amount">

              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>Approval Code</mat-label>
                <input matInput type="text" [(ngModel)]="paymentAuthorization.approvalCode" [value]="paymentAuthorization.approvalCode">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>Authorization Code</mat-label>
                <input matInput type="text" [(ngModel)]="paymentAuthorization.authorizationCode" [value]="paymentAuthorization.authorizationCode">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>Response Code</mat-label>
                <input matInput type="text" [(ngModel)]="paymentAuthorization.responseCode" [value]="paymentAuthorization.responseCode">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <mat-form-field style="width:100%"   class="process-input" >
                <mat-label>Capture Possible</mat-label>
                <mat-select [(ngModel)]="paymentAuthorization.capturePossible" [value]="paymentAuthorization.capturePossible" >
                  <mat-option value="false"> false </mat-option>
                  <mat-option value="true"> true </mat-option>
                </mat-select>
              </mat-form-field>

            </td>
          </tr>
        </table>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="processPaymentAuthorization()" cdkFocusInitial>Process</button>
    </mat-dialog-actions>
  </div>
</ng-template>


<ng-template #debtRecoveryModal  >
  <div style="width: 400px;">
    <h2 mat-dialog-title>Process</h2>
    <mat-dialog-content class="mat-typography">
      <table>
        <tr>
          <td colspan="2">
            <mat-form-field style="width:100%"  appearance="fill">
              <mat-label>Response Status</mat-label>
              <mat-select [(ngModel)]="debtRecovery.responseStatus">
                <mat-option value="-">  </mat-option>
                <mat-option value="HARD_DECLINE"> Hard Declined </mat-option>
                <mat-option value="SOFT_DECLINE"> Soft Declined </mat-option>
                <mat-option value="APPROVED"> Approved </mat-option>
                <mat-option value="PARTIALLY_APPROVED"> Partially approved  </mat-option>
              </mat-select>
            </mat-form-field>

          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Amount</mat-label>
              <input matInput [disabled]="debtRecovery.responseStatus != 'PARTIALLY_APPROVED'" type="number" [(ngModel)]="debtRecovery.amount" [value]="debtRecovery.amount">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Approval Code</mat-label>
              <input matInput type="text" [(ngModel)]="debtRecovery.approvalCode" [value]="debtRecovery.approvalCode">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Authorization Code</mat-label>
              <input matInput type="text" [(ngModel)]="debtRecovery.authorizationCode" [value]="debtRecovery.authorizationCode">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Response Code</mat-label>
              <input matInput type="text" [(ngModel)]="debtRecovery.responseCode" [value]="debtRecovery.responseCode">
            </mat-form-field>
          </td>
        </tr>
      </table>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="processDebtRecovery()" cdkFocusInitial>Process</button>
    </mat-dialog-actions>
  </div>
</ng-template>


