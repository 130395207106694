import { PaymentAuthorizationResponseStatus } from './PaymentAuthorizationResponseStatus';

export class DebtRecoveryRequest {
  seqId: number;
  responseStatus: PaymentAuthorizationResponseStatus;
  amount: number;
  authorizationCode: string;
  approvalCode: string;
  responseCode: string;
}
