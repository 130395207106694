// Angular Modules
import { Injectable } from '@angular/core';
import { ApiHttpService } from "./api-http.service";
import * as moment from 'moment';
import { Token } from "../models/Token";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";


@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private httpService: ApiHttpService) {

  }

  checkTokenExpire() {
    this.getExpiration();
  }

  getToken(): string {
    if (sessionStorage.getItem('token')) {
      return sessionStorage.getItem('token');
    }
    return null;
  }

  getRefreshToken(): string {
    if (sessionStorage.getItem('refreshToken')) {
      return sessionStorage.getItem('refreshToken');
    }
    return null;
  }

  /**
   * Get jwt token
   * @returns {string}
   */
  isLogged(): boolean {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }


  refreshToken() {
    return this.httpService.refreshToken(sessionStorage.getItem('refreshToken'));
  }

  refreshToken1(): Observable<Token> {
    return this.httpService.refreshToken(sessionStorage.getItem('refreshToken')).pipe(
      tap(response =>
        this.saveSession(response)),
      catchError ( err=> {
        this.logout();
        return throwError(err);
      })
    );
  }


  /**
   * Try a login with the access token.
   *
   * @param {Credentials} credentials - Contains user credentials
   * @returns {Promise}
   */
  login(username, password) {
    return this.httpService.login(this.httpBasic(username, password));

  }

  encodeBase64(string) {
    return btoa(string)
  }


  /**
   * Encode username,password and add 'Basic '.
   *
   * @param {String} username
   * @param {String} password
   * @returns {String}
   */
  httpBasic(username, password) {
    return 'Basic ' + this.encodeBase64(username + ':' + password);
  }

  getExpiration() {
    const expiration = sessionStorage.getItem('expires_in');
    return moment(expiration);
  }

  saveSession(data) {
    const token = data["access_token"];
    const refreshToken = data["refresh_token"];
    const expireIN = moment().add(data["expires_in"], "seconds");
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("expires_in", expireIN.toISOString());
    if (refreshToken) sessionStorage.setItem("refreshToken", refreshToken);
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('expires_in');
    window.location.reload();
  }


}
