import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {ApiHttpService} from '../core/api-http.service';
import {Configuration} from '../models/Configuration';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {fromEvent} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from "ng-pick-datetime";
import {MomentDateTimeAdapter} from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class";
import {AcceptListDataSource} from "../services/AcceptListDataSource";

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Component({
  selector: 'app-home',
  templateUrl: './acceptlist.component.html',
  styleUrls: ['./acceptlist.component.scss'],
  providers: [
    {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS}
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AcceptlistComponent implements OnInit {

  dataSource: AcceptListDataSource;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('input', {static: true}) input: ElementRef;


  start = null;
  end = null;
  search = "";
  alert = "";
  alertMessage = "";
  correlationId: string;
  paymentSchemeIdentification: string;
  cardUpdated = false;
  interval: any;
  expandedElement: any | null = null;
  total: number;
  allConfigurations: Map<string, Configuration>;
  configurations: Configuration[] = [];
  displayedColumns = ["surrogatePan", "merchantId", "truncatedPan"];
  displayedColumnFilters: string[] = [
    'filter'
  ];

  constructor(private httpService: ApiHttpService, private dialog: MatDialog) {
  }


  searchByFilter() {
    this.paginator.pageIndex = 0;
    let filter = this.search;
    this.dataSource.loadAcceptList(filter, filter, this.paginator.pageIndex, this.paginator.pageSize)
  }

  ngOnInit(): void {

    this.dataSource = new AcceptListDataSource(this.httpService);
    this.dataSource.loadAcceptList(null, null, 0, 10);
    this.total = this.dataSource.total();
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadAcceptList(null, null, this.paginator.pageIndex, this.paginator.pageSize))
      )
      .subscribe();

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchByFilter();
        })
      )
      .subscribe();
  }
}
