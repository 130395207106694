<div class="topnav" *ngIf='showNav' style="display: flex;justify-content: space-between">
  <div>
    <a routerLink="/home" routerLinkActive="active" >Home</a>
    <a routerLink="/acceptList" routerLinkActive="active">Accept List</a>
    <a routerLink="/denyList" routerLinkActive="active">Deny List</a>
    <a routerLink="/denyListVersion" routerLinkActive="active">Deny List Version</a>
    <a routerLink="/provisionEMVMedia" routerLinkActive="active">Provision EMV Media</a>
  </div>
  <div>
      <a (click)="logout()" > Logout</a>
  </div>
</div>

<router-outlet></router-outlet>
