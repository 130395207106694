import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ApiHttpService } from "../core/api-http.service";
import { catchError, finalize } from 'rxjs/operators';

export class DenyListService implements DataSource<any> {

  private denyMediaListSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalElements: number;
  public loading$ = this.loadingSubject.asObservable();

  constructor(private httpService: ApiHttpService) {
  }

  loadMediaDenyList(pageIndex =0,pageSize=10, surrogatePAN?:string, truncatedPan?:string, ) {

    this.loadingSubject.next(true);

    this.httpService.getDenyMediaList(pageIndex, pageSize, surrogatePAN, truncatedPan)
      .pipe(catchError(() => of([])),finalize(() => this.loadingSubject.next(false)))
      .subscribe(media => {
        this.denyMediaListSubject.next(media.content);
        this.totalElements = media.page.totalElements;
      });
  }

  total(){
    return this.totalElements;
  }

  connect(httpService: CollectionViewer): Observable<any[]> {
    return this.denyMediaListSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.denyMediaListSubject.complete();
    this.loadingSubject.complete();
  }
}
